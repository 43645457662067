import store from '@/state/store';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/account/login.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/',
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: () => import('../views/account/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/account/register.vue'),
    meta: {
      title: 'Register',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'default'
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('../views/account/forgot-password.vue'),
    meta: {
      title: 'Forgot Password',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'default'
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: '/role-permission',
    name: 'permissions',
    meta: {
      title: 'Roles & Permissions',
      authRequired: true,
      requiresPermission: 'role-list'
    },
    component: () => import('../views/permissions')
  },
  {
    path: '/clients',
    name: 'clients',
    meta: {
      title: 'Clients',
      authRequired: true,
      requiresPermission: 'client-list'
    },
    component: () => import('../views/clients')
  },
  {
    path: '/departments',
    name: 'departments',
    meta: {
      title: 'departments',
      authRequired: true,
      requiresPermission: 'department-list'
    },
    component: () => import('../views/departments')
  },
  {
    path: '/departments/:id/view',
    name: 'departments-view',
    meta: {
      title: 'Department View ',
      authRequired: true,
      requiresPermission: 'department-view'
    },
    props: true,
    component: () => import('../views/departments/view')
  },
  {
    path: '/positions',
    name: 'positions',
    meta: {
      title: 'Positions',
      authRequired: true,
      requiresPermission: 'position-list'
    },
    component: () => import('../views/positions')
  },
  {
    path: '/employees',
    name: 'employees',
    meta: {
      title: 'Employees',
      authRequired: true,
      requiresPermission: 'user-list'
    },
    component: () => import('../views/users')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'Profile',
      authRequired: true
    },
    component: () => import('../views/users/profile')
  },
  {
    path: '/employee/:id/view',
    name: 'users.view',
    meta: {
      title: 'View Profile',
      authRequired: true,
      requiresPermission: 'user-view'
    },
    props: true,
    component: () => import('../views/users/view')
  },
  {
    path: '/profile/edit',
    name: 'profile-setting',
    meta: {
      title: 'Setting',
      authRequired: true
    },
    component: () => import('../views/users/setting')
  },
  {
    path: '/projects',
    name: 'projects',
    meta: {
      title: 'Projects',
      authRequired: true
    },
    component: () => import('../views/projects/index')
  },
  {
    path: '/projects/create',
    name: 'projects-create',
    meta: {
      title: 'Create Project',
      authRequired: true,
      requiresPermission: 'project-create'
    },
    component: () => import('../views/projects/create')
  },
  {
    path: '/projects/:id/edit',
    name: 'projects-edit',
    meta: {
      title: 'Update Project',
      // FIXME: authRequired: true, update the flow to not required Auth
      authRequired: false,
      requiresPermission: 'project-edit'
    },
    props: true,
    component: () => import('../views/projects/edit')
  },
  {
    path: '/projects/:id/view',
    name: 'projects-view',
    meta: {
      title: 'View Project',
      authRequired: true,
      requiresPermission: 'project-view'
    },
    props: true,
    component: () => import('../views/projects/view')
  },
  {
    path: '/projects/:id/tasks',
    name: 'project-tasks',
    meta: {
      title: 'View Project Tasks',
      authRequired: true
    },
    props: true,
    component: () => import('../views/tasks/task-kanban.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Dashboard',
      authRequired: true
    },
    component: () => import('../views/dashboard/index.vue')
  },

  {
    path: '/logout',
    name: 'logout',
    meta: {
      title: 'Logout',
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      }
    },
    component: () => import('../views/account/login.vue')
  },

  {
    path: '/project/tasks-details/:id',
    name: 'tasks-details',
    meta: {
      title: 'Tasks Details',
      authRequired: true
    },
    component: () => import('../views/tasks/task-view-details.vue')
  },

  {
    path: '/invoices/list',
    name: 'invoice-list',
    meta: {
      title: 'Invoice List',
      authRequired: true
    },
    component: () => import('../views/invoices/list')
  },
  {
    path: '/invoices/:mode/:id?',
    name: 'payment-view-upsert',
    meta: {
      title: 'Create Invoice',
      authRequired: true
    },
    component: () => import('../views/invoices/payment-view-upsert')
  },
  {
    path: '/analytics/user/:id?',
    name: 'analytics-user',
    meta: {
      title: 'User analytics',
      authRequired: true
    },
    component: () => import('../views/analytics/user-analytics.vue')
  },
  {
    path: '/analytics/project/:id?',
    name: 'analytics-project',
    meta: {
      title: 'Project analytics',
      authRequired: true
    },
    component: () => import('../views/analytics/project-analytics.vue')
  },
  {
    path: '/analytics/task/:id?',
    name: 'analytics-task',
    meta: {
      title: 'Task analytics',
      authRequired: true
    },
    component: () => import('../views/analytics/task-analytics.vue')
  },

  {
    path: '/forms/list',
    name: 'forms-list',
    meta: {
      title: 'Forms List',
      authRequired: true
    },
    component: () => import('../views/form-approvals/list')
  },
  {
    path: '/forms/:mode/:id?',
    name: 'forms-view-upsert',
    meta: {
      title: 'Create Form',
      authRequired: true
    },
    component: () => import('../views/form-approvals/forms-view-upsert')
  },

  {
    path: '/leaves/list',
    name: 'leaves-list',
    meta: {
      title: 'Leaves',
      authRequired: true
    },
    component: () => import('../views/leaves/list')
  },
  {
    path: '/leaves/:mode/:id?',
    name: 'leaves-view-upsert',
    meta: {
      title: 'Create Invoice',
      authRequired: true
    },
    component: () => import('../views/leaves/leaves-view-upsert')
  },

  {
    path: '/news-section',
    name: 'news-sections',
    meta: {
      title: 'News Section',
      authRequired: true
    },
    component: () => import('../views/news-sections/index.vue')
  },

  {
    path: '/pages/maintenance',
    name: 'maintenance',
    meta: {
      title: 'Maintanance',
      authRequired: true
    },
    component: () => import('../views/pages/maintenance')
  },
  {
    path: '/pages/coming-soon',
    name: 'coming-soon',
    meta: {
      title: 'Comming Soon',
      authRequired: true
    },
    component: () => import('../views/pages/coming-soon')
  },

  {
    path: '/pages/faqs',
    name: 'FAQs',
    meta: {
      title: 'FAQs',
      authRequired: true
    },
    component: () => import('../views/pages/faqs')
  },
  {
    path: '/pages/pricing',
    name: 'pricing',
    meta: {
      title: 'Pricing',
      authRequired: true
    },
    component: () => import('../views/pages/pricing')
  },

  {
    path: '/pages/sitemap',
    name: 'sitemap',
    meta: {
      title: 'Sitemap',
      authRequired: true
    },
    component: () => import('../views/pages/sitemap')
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy',
      authRequired: true
    },
    component: () => import('../views/pages/privacy-policy')
  },
  {
    path: '/pages/term-conditions',
    name: 'term-conditions',
    meta: {
      title: 'Term Conditions',
      authRequired: true
    },
    component: () => import('../views/pages/term-conditions')
  },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: 'Signin Basic',
      authRequired: true
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: 'Signin Cover',
      authRequired: true
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: 'Signup Basic',
      authRequired: true
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: 'Signup Cover',
      authRequired: true
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      title: 'Reset Password'
      // authRequired: true,
    },
    component: () => import('../views/account/reset-password'),
    beforeEnter: (to, from, next) => {
      // Assuming your logic to check if the email and token are valid
      if (to.query.token && to.query.email) {
        // You might want to validate the token here or in the component
        next();
      } else {
        next({
          name: 'Forgot password'
        }); // Redirect to forgot password page
      }
    }
  },

  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: 'Error 404',
      authRequired: true
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: 'Error 500',
      authRequired: true
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/access_denied',
    name: 'access_denied',
    meta: {
      title: 'Access Denied',
      authRequired: false
    },
    component: () => import('../views/auth/errors/accessDenied.vue')
  },
  {
    path: '/auth/expirePlan',
    name: 'expirePlan',
    meta: {
      title: 'Expire Plan',
      authRequired: false
    },
    component: () => import('../views/auth/errors/expirePlan.vue')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: 'Error 404',
      authRequired: true
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: 'Error 404',
      authRequired: true
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: 'Offline',
      authRequired: true
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: 'Logout',
      authRequired: true
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: 'Logout',
      authRequired: true
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/time-tracking',
    name: 'time-tracking',
    meta: {
      title: 'time-tracking',
      authRequired: true
    },
    component: () => import('../views/time-tracking/index')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: 'Success Message',
      authRequired: true
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: 'Success Message',
      authRequired: true
    },
    component: () => import('../views/auth/success-msg/cover')
  },

  {
    path: '/organization-chart',
    name: 'organization-chart',
    meta: {
      title: 'Organization Chart',
      authRequired: true
    },
    component: () => import('../views/organization-chart/index')
  },
  {
    path: '/car-reservation',
    name: 'car-reservation',
    meta: {
      title: 'Car Reservation',
      authRequired: true
    },
    component: () => import('../views/car-reservation/index')
  },
  {
    path: '/driver-reservation',
    name: 'driver-reservation',
    meta: {
      title: 'Driver Reservation',
      authRequired: true
    },
    component: () => import('../views/driver-reservation/list.vue')
  },

  {
    path: '/driver/:mode/:id?',
    name: 'driver-view-upsert',
    meta: {
      title: 'Create Driver',
      authRequired: true
    },
    component: () => import('../views/driver-reservation/driver-view-upsert')
  },
  {
    path: '/room',
    name: 'room',
    meta: {
      title: 'Room',
      authRequired: true,
      requiresPermission: 'room-list'
    },
    component: () => import('../views/room-reservation/room')
  },
  {
    path: '/room/building',
    name: 'building',
    meta: {
      title: 'Building',
      authRequired: true
    },
    component: () => import('../views/room-reservation/building/index')
  },
  {
    path: '/room/building',
    name: 'building',
    meta: {
      title: 'Building',
      authRequired: true
    },
    component: () => import('../views/room-reservation/building/index')
  },
  {
    path: '/room-reservation',
    name: 'RoomReservation',
    meta: {
      title: 'Room Reservation',
      authRequired: true
    },
    component: () => import('../views/room-reservation/index')
  },
  {
    path: '/room/building/:id',
    name: 'building-view',
    meta: {
      title: 'Building',
      authRequired: true
    },
    component: () => import('../views/room-reservation/building/view')
  },
  {
    path: '/groups',
    name: 'groups',
    meta: {
      title: 'Groups',
      authRequired: true
    },
    component: () => import('../views/groups/index')
  },
  {
    path: '/cars',
    name: 'cars',
    meta: {
      title: 'Cars',
      authRequired: true,
      requiresPermission: 'car-list'
    },
    component: () => import('../views/cars/index')
  },
  {
    path: '/privacyPolicy',
    name: 'privacy-policy',
    component: () => import('../views/privacy_policy/index')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/auth/errors/404'),
    props: true
  }
];
