import axios from 'axios';
import VueAxios from 'vue-axios';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init(app) {
    app.use(VueAxios, axios);
    axios.defaults.baseURL = process.env.VUE_APP_URL;
    // axios.defaults.baseURL = "https://tracker.tornest.net:1337/api";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
    axios.defaults.headers.common['Access-Control-Allow-methods'] = `*`;
    this.setHeader();
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    axios.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    });
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return axios.get(`${resource}`).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param isFormData
   * @param config
   * @returns {*}
   */
  post(resource, params, isFormData = false, config = {}) {
    if (!isFormData) return axios.post(`${resource}`, params);
    else {
      const formData = this.convertObjectToFormData(params);
      return axios.post(`${resource}`, formData, config);
    }
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param isFormData
   * @param config
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, isFormData = false, config = {}) {
    if (!isFormData) return axios.put(`${resource}`, params, config);
    else {
      const formData = this.convertObjectToFormData(params);
      return axios.put(`${resource}`, formData, config);
    }
  },

  patch(resource, params) {
    return axios.patch(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  convertObjectToFormData(obj, formData = new FormData(), parentKey = '') {
    if (obj && typeof obj === 'object' && !(obj instanceof Date) && !(obj instanceof File)) {
      Object.keys(obj).forEach((key) => {
        this.convertObjectToFormData(obj[key], formData, parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      formData.append(parentKey, obj);
    }
    return formData;
  }
};

export default ApiService;
